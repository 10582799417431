import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import * as styles from "./SpacesSlider.module.scss";
import ArrowRight from "../../../svg/icon-arrow-right.svg";
import ArrowLeft from "../../../svg/icon-arrow-left.svg";
const SpacesSlider = () => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
     setWidth(window.innerWidth);
  });
  const settings = {
    centerMode: false,
    slidesToShow: 1,
    infinite: true,
    dots: false,
    arrows:true,
    prevArrow:<div><ArrowLeft/></div>,
    nextArrow:<div><ArrowRight /></div>,
    centerPadding: width >= 768 ? '20%' : 'none',

  };
 const data = [
{
    image:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671811341/meetingbackgrounds/hotel2.4_hpj1mw.jpg",
    alt: "3D interior design of a premium hotel space with books on the wall and the Master Wizr logo displayed prominently."
},
{
    image:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671811346/meetingbackgrounds/hotel2.7_f4wlmd.jpg",
    alt: "Interior design of a workspace or hotel reception with MasterWizr logo embedded on the wall."
},
{
    image:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671811342/meetingbackgrounds/hotel2.1_yzzzvr.jpg",
    alt: "Office or hotel area with a sofa, large bookshelves, and the MasterWizr logo embedded in the wall."
},
{
    image:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671811342/meetingbackgrounds/hotel2.2_tl1x50.jpg",
    alt: "3D interior design of a premium hotel space with books on the wall and the Master Wizr logo displayed prominently."
},
]
  return (
  <div className={`${styles.container} spaceSlider`}>
      <h1><span>3D Spaces</span> - Ready to be enjoyed</h1>
    <Slider {...settings}>
    {data.map((item,i)=>{
        return(
          <div className={styles.item}>
              {/* <h1 dangerouslySetInnerHTML={{ __html: item.title }}></h1> */}
               <img src={item.image} key={i} alt={item?.alt}/>
          </div> 
        )
    })}
    </Slider>
  </div>
  );
};
export default SpacesSlider;