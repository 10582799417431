import React, { useEffect, useState } from "react";
import AutoPlaySlider from "../../molecules/AutoPlaySlider/AutoPlaySlider";
import * as styles from "./SpacesGrid.module.scss";

function SpacesGrid() {
  const [width, setWidth] = useState(0);
  useEffect(() => {
     setWidth(window.innerWidth);
  });
    const data = [
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679954804/web%20images/3d_first_one_r69ybc.png"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679954804/web%20images/3d_second_one_gizpax.png"
        },
        {
            url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679954805/web%20images/3d_third_onee_q410n4.png"
        },
        {
            url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679954805/web%20images/3d_fourth_oneee_m78pzg.png"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679954804/web%20images/int_mod_04_use_case_02_uyyg59_j70z6b.jpg"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679954805/web%20images/int_mod_05_use_case_01_riwvuf_wicc4a.jpg"
        },
        {
            url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679954806/web%20images/int_mod_02_use_case_02_zdahzy_rrqric.jpg"
        }
      ]
  return (
    <div className={styles.spacesGrid}>
      <div className={styles.content}>
        <h1>Enjoyable, Empowering and Engaging  </h1>
       {width >= 768 &&  <p>
        Elevate your video conferencing experience and embrace playfulness to combat digital fatigue.
        </p>}
      </div>
      <div className={styles.sliderContainer}>
          <AutoPlaySlider {...{data, slideNumber:width>=768 ? 4 : 1.5,extraClass:"spacesSlider"}}/>
      </div>
    </div>
  );
}
export default SpacesGrid;
