import * as styles from "./AutoPlaySlider.module.scss";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";

function AutoPlaySlider({ extraClass, data, autoPlaySpeed, slideNumber, video, bg }) {
  const [isHovered, setIsHovered] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const sliderRef = useRef(null);
  const videoRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const slider = sliderRef.current;

    const handleMouseEnter = () => {
      setIsHovered(true);
      if (slider.innerSlider.state.autoplaying) {
        slider.innerSlider.pause("paused");
      }
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
      if (!slider.innerSlider.state.autoplaying) {
        slider.innerSlider.autoPlay("play");
      }
    };

    const sliderElement = slider.innerSlider.list;
    sliderElement.addEventListener("mouseenter", handleMouseEnter);
    sliderElement.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      sliderElement.removeEventListener("mouseenter", handleMouseEnter);
      sliderElement.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;

    if (isHovered) {
      slider.innerSlider.pause("paused");
    } else {
      slider.innerSlider.autoPlay("play");
    }
  }, [isHovered]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
      if (!document.fullscreenElement) {
        setShowVideo(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowVideo(false);
        exitFullscreen();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const toggleFullScreen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;

    const exitFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (!isFullScreen) {
      if (requestFullScreen) {
        requestFullScreen.call(docEl);
      }
    } else {
      if (exitFullScreen) {
        exitFullScreen.call(doc);
      }
    }
  };

  const exitFullscreen = () => {
    const doc = window.document;

    const exitFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (exitFullScreen) {
      exitFullScreen.call(doc);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slideNumber ? slideNumber : 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5500,
    autoplaySpeed: autoPlaySpeed ? autoPlaySpeed : 100,
    cssEase: "linear",
    arrows: false,
  };

  return (
    <div>
      <div  style={{background: bg}} className={`${styles.slider} ${styles[extraClass]}`}>
        <Slider ref={sliderRef} {...settings}>
          {data?.map((item, i) => {
            return (
              <div key={i} className={styles.item}>
                <img src={item.url} alt="pres" />
                <button onClick={() => {
                  setVideoUrl(item.videoUrl ? item.videoUrl : item.url);
                  toggleFullScreen();
                  setShowVideo(true);
                }}>
                  {video ? "View Video" : "Preview Live"}
                </button>
              </div>
            );
          })}
        </Slider>
      </div>
      {showVideo && (
        <div className={styles.videoContainer}>
          {video ? (
          <video
            ref={videoRef}
            width="100%"
            height="100%"
            src={videoUrl}
            autoPlay={false}
            loop
            muted
            controls
            controlsList="nodownload nofullscreen noremoteplayback"
          />
          ):(
          <iframe
            width="100%"
            height="100%"
            src={videoUrl}
          />
          )}
          <button onClick={() => setShowVideo(false)}>Close Video</button>
          <button className={styles.fullscreenVideo} onClick={toggleFullScreen}>{isFullScreen ? 'Go Back' : 'Fullscreen'}</button>
        </div>
      )}
    </div>
  );
}

export default AutoPlaySlider;
