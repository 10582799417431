import React, { useEffect, useState } from "react";
import AutoPlaySlider from "../AutoPlaySlider/AutoPlaySlider";
import * as styles from "./SpacesGrid.module.scss";

function SpacesGrid() {
  const [width, setWidth] = useState(0);
  useEffect(() => {
     setWidth(window.innerWidth);
  });
    const data = [
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953986/web%20images/learnio_lab_01_qij3au_xluuwu.jpg"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953986/web%20images/learnio_lab_02_x9iyco_bjqojt.jpg"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953987/web%20images/pangea_3D_spaces_04_bsbf8c_a5o2pw.jpg"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953990/web%20images/pangea_3D_spaces_06_gxontu_rcxzgy.jpg"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953986/web%20images/3D_spaces_Learnio_Open_office_1_jrtzna_hph3kb.jpg"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953986/web%20images/learnio_lab_03_2_ugzjvt_moannj.jpg"
        },
        {
          url:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953985/web%20images/pangea_3D_spaces_03_nlcu2m_qtzczf.jpg"
        },
      ]
  return (
    <div className={styles.spacesGrid}>
      <div className={styles.content}>
        <h1><span>3D Spaces</span> - Premium Customization </h1>
       {width>=768 &&  <p>
        Whether you want a white-labeled video conferencing solution or custom 3D Spaces, we've got you covered.
        </p>}
      </div>
      <div className={styles.sliderContainer}>
          <AutoPlaySlider {...{data, slideNumber:width>=768 ? 4 : 1.5,extraClass:"spacesSlider"}}/>
      </div>
    </div>
  );
}
export default SpacesGrid;
