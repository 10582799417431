import React, { useEffect } from "react";
import Header from "../components/atoms/header";
import AOS from "aos";
import "aos/dist/aos.css";
import ConnectTop from "../components/ogranisms/ConnectTop/ConnectTop";
import SpaceSteps from "../components/ogranisms/SpaceSteps/SpaceSteps";
import SpacesSlider from "../components/ogranisms/SpacesSlider/SpacesSlider";
import SpacesGrid from "../components/ogranisms/SpacesGrid/SpacesGrid";
import SpacesGridTop from "../components/molecules/SpacesGridTop/SpacesGrid";
import * as styles from "./styles.module.scss";
import SpacesDivider from "../components/ogranisms/SpacesDivider/SpacesDivider";
import VisualPresence from "../components/ogranisms/virtualPresence";
import { Helmet } from "react-helmet";

function Spaces() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <main>
      <Helmet>
        <title>3D Spaces</title>
        <link rel="canonical" href="https://masterwizr.com/3d-spaces" />
      </Helmet>
      <Header />
      <ConnectTop title="3D Spaces" desc={"Virtual Presence made Real"} />
      <div className={styles.dividerSpaces}>
        <h1>
          {" "}
          3D Spaces are curated virtual spaces built from scratch, designed for our video solution, and ideal for your marketing needs. 
        </h1>
      </div>
      <SpacesGrid />
      <SpaceSteps />
      <SpacesSlider />
      <div className={styles.spacesbottom}>
        <img
        alt="Poster showcasing impressive virtual backgrounds in Wizr Connect."
          src={
            "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953986/web%20images/magconnect5-0515bdba71b5c61bc794917c8d4605ff_kdj2ur.png"
          }
        />
      </div>
      <VisualPresence />
      <SpacesDivider />
      <div className={`${styles.divideIndex} ${styles.divide2}`}></div>
      <SpacesGridTop />
      <div className={styles.bottomspacedivider}>
        <img
        alt="solution"
          src={
            "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679954620/web%20images/Screenshot_2023-03-27_at_2.16.42_PM_cixqyl_1_gozvez.png"
          }
        />
        <h1>
          <span>Curious about 3D? </span> Check out your options
        </h1>
        <button>Send Your Design Request</button>
      </div>
    </main>
  );
}
export default Spaces;
