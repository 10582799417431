import React from "react";
import * as styles from "./SpaceSteps.module.scss";

function SpaceSteps() {
  const data = [
    {
      title: "01",
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953987/web%20images/Rectangle_146_ybz0hp_ejx6oc.png",
    },
    {
      title: "02",
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953987/web%20images/Rectangle_147_vdbxcs_z1hoxb.png",
    },
    {
      title: "03",
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953988/web%20images/Rectangle_148_dpd3b7_atktrf.png",
    },
    {
      title: "04",
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953988/web%20images/Rectangle_149_isiq5d_qgckpx.png",
    },
  ];
  return (
    <div className={styles.container}>
      <h1>3D Spaces in the Making</h1>
      <div className={styles.grid}>
        {data.map((item, i) => {
          return (
            <div
              key={i}
              style={{ backgroundImage: `url(${item.image})` }}
              className={styles.item}
            >
              <p>{item.title}.</p>

            </div>
          );
        })}
      </div>
    </div>
  );
}
export default SpaceSteps;
