import React, { useEffect, useState } from "react";
import * as styles from "./SpacesDivider.module.scss";


function SpacesDivider(){
    const [width, setWidth] = useState(0);
    useEffect(() => {
       setWidth(window.innerWidth);
    });
    return(
    <>
    {
        width>=768 ?
        <div className={styles.container}>
        <div className={styles.content}>
            <img alt="3d space" src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679953988/web%20images/stages_large_07_02_p3pfhf_qgvmm4.jpg" />
            <div className={styles.text}>
                <h1> 3D Spaces -</h1>
                <h1 className={styles.lastHeading}>Areas of Use</h1>
                <p>• Included in WiZR Connect </p>
                <p>• Suitable for Websites</p>
                <p>• Video Green Screen Productions</p>
                <p>• Podcast and Studio Backgrounds</p>
                <p>• Training and Course Material</p>
                <p>• Presentations and Sales Material</p>
                <p>• Investor Pitches</p>


            </div>
        </div>
       </div>
       :""

    }
    </>
    );
}
export default SpacesDivider;