import React from "react";
import * as styles from './ConnectTop.module.scss';


function index({title,desc}) {
  return (
    <div className={styles.container}>
         <div className={styles.content}>
          <h2  data-aos-delay="200">{title}</h2>
         <p  data-aos-delay="100">{desc}</p>
         </div>
    </div>
  );
}

export default index;