import React, { useState } from "react";
import * as styles from "./virtualPresence.module.scss";
function Index() {
const [state,setState]=useState(0);
  return (
    <div className={styles.virtualPresence}>
      <div className={styles.topSection}>
          <h1>3D Spaces for Teams</h1>
          <p>
          Explore how teams can be in the same virtual spaces - check out the WiZR Connect video conferencing solution
          </p>
      </div>
      <div className={styles.tabs}>
        <button className={state===0 ? styles.active : ``} onClick={()=>setState(0)}>Lounge</button>
        <button className={state===1 ? styles.active : ``} onClick={()=>setState(1)}>Premium</button>
        <button className={state===2 ? styles.active : ``} onClick={()=>setState(2)}>Casual</button>

      </div>
      {state === 0 ?
      <div
      id="scrollSection"
      className={styles.carousel}
    >
      <div className={styles.contain}>
        <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671059635/meetingbackgrounds/lounge11_odwdbe.jpg"} alt='MasterWizr'/>
        <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671059635/meetingbackgrounds/lounge44_mwtu3v.jpg"} alt='MasterWizr'/>
        <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671059635/meetingbackgrounds/lounge33_ojtygf.jpg"} alt='MasterWizr'/>
        <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671059636/meetingbackgrounds/lounge55_a6hl2n.jpg"} alt='MasterWizr'/>
        <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1671059636/meetingbackgrounds/lounge22_jrtcys.jpg"} alt='MasterWizr'/>
      </div>
    </div>:
  state===1 ?
  <div
  id="scrollSection"
  className={styles.carousel}
>
  <div className={styles.contain}>
    <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673385868/meetingbackgrounds/Conference_1_rjybgx.jpg"} alt='MasterWizr'/>
    <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673385868/meetingbackgrounds/Conference_6_ba71f4.jpg"} alt='MasterWizr'/>
    <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673385869/meetingbackgrounds/Conference_2_sqrmkv.jpg"} alt='MasterWizr'/>
    <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673385868/meetingbackgrounds/Conference_5_pzaamb.jpg"} alt='MasterWizr'/>
    <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673385868/meetingbackgrounds/Conference_3_wrbpht.jpg"} alt='MasterWizr'/>
    <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673385869/meetingbackgrounds/Conference_7_nsrlw6.jpg"} alt='MasterWizr'/>
    <img src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673385870/meetingbackgrounds/Conference_4_ceglps.jpg" alt="MasterWizr"/>
  </div>
</div>
:
<div
id="scrollSection"
className={styles.carousel}
>
<div className={styles.contain}>
  <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680280910/web%20images/U.E._Scene_3_awpzez.jpg"} alt='MasterWizr'/>
  <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680280910/web%20images/U.E._Scene_1_eeta1s.jpg"} alt='MasterWizr'/>
  <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680280909/web%20images/U.E._Scene_2_hm8xlo.jpg"} alt='MasterWizr'/>
  <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680280910/web%20images/U.E._Scene_4_vt6rgx.jpg"} alt='MasterWizr'/>
  <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680280910/web%20images/U.E._Scene_6_tjb9l7.jpg"} alt='MasterWizr'/>
  <img  src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680280911/web%20images/U.E._Scene_7_swg94h.jpg"} alt='MasterWizr'/>
  <img src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680280910/web%20images/U.E._Scene_5_h2ldtd.jpg"  alt="MasterWizr"/>
</div>
</div>
      }
    </div>
  );
}

export default Index;
